import React from 'react';

import { Flex } from '../styles/general.style';

import Notifications from '../components/settings/notifications.container.js';

const SettingsPage = () => (
	<Flex direction='column'>
		<h2>Paramètres</h2>
		<h2>Soon</h2>
		<Notifications />
	</Flex>
);

export default SettingsPage;