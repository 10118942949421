import io from 'socket.io-client';

import { FETCH_NOODLE } from '../actions/fetch.action';
import { RESET_NOODLE } from '../actions/dispatch.action';
import { FRONT_URL } from '../App';

export default function (state = {}, action) {

	switch (action.type) {
		case FETCH_NOODLE:
			const token = localStorage.getItem('noodleToken');
			
			const socket = io(FRONT_URL, { query: `token=${token}&noodleId=${action.payload.data.noodleId}` });
			// For socket listeners see in components
			return { ...action.payload.data, socket } || state;
		case RESET_NOODLE:
			return {};
		default:
			return state;
	}
}