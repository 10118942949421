import React from 'react';

import RadioGroup from './radiogroup.container';
import TableRow from './tablerow.component';

import { connect } from 'react-redux';

import { Table } from 'semantic-ui-react';

import { Scrollable, Flex } from '../../../styles/general.style';

const NoodleTable = ({anonymous, noodle, opened, user}) => (
	<Flex justify='center'>
	<Scrollable>
			<Table 
				collapsing 
				unstackable 
				celled 
				definition
				textAlign='center'
				style={{alignSelf: 'center'}}
			>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						{noodle.entries.map(ent => (
							<Table.HeaderCell>{ent.name}</Table.HeaderCell>
						))}
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{anonymous ? 
						noodle.userVotes
						.filter(usr => usr.login === user.login)
						.map(user => <TableRow user={user} />) :
						noodle.userVotes.map(user => <TableRow user={user} />)
					}
					{opened && 
						<Table.Row>
							<Table.Cell>
								{user.fullName}
							</Table.Cell>
							{noodle.entries.map((ent, idx) => (
								<Table.Cell>
									<RadioGroup answer={noodle.voted && noodle.voted[idx].answer} number={ent.number} maybe={ent.maybe} />
								</Table.Cell>
							))}
						</Table.Row>
					}
					{(!anonymous || noodle.admin) &&
						<Table.Row>
							<Table.Cell>
								Résultats : {noodle.voteNumber} réponse{noodle.voteNumber <= 1 ? '' : 's'}
							</Table.Cell>
							{noodle.entries.map(ent =>(
								<Table.Cell>
									{ent.yesCount + (ent.maybeCount ? ` (+ ${ent.maybeCount})` : '')}
								</Table.Cell>
							))}
						</Table.Row>
					}
										
				</Table.Body>
				{// Render options downside if there are several votes
						(noodle.userVotes.length > 10) && (
							<Table.Footer>
								<Table.Row>
									<Table.HeaderCell />
									{noodle.entries.map(ent => (
										<Table.HeaderCell>{ent.name}</Table.HeaderCell>
									))}
								</Table.Row>
							</Table.Footer>)
					}
			</Table>
	</Scrollable>
	</Flex>
);

function mapStateToProps({ user }){
	return { user };
}

export default connect(mapStateToProps)(NoodleTable);
