import { FETCH_NOODLE_NUMBER } from '../actions/fetch.action';

export default function(state={}, action){
	
	switch(action.type){
		case FETCH_NOODLE_NUMBER:
			return action.payload.data || state;
		default:
			return state;
	}
}