import React, { Component } from 'react';
import { ResponsiveContainer, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar } from 'recharts';
import { Button, Header, Modal, Icon } from 'semantic-ui-react';

export default class Chart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: false
		};
	}

	handleClose = (event) => {
		this.setState({
			opened: false
		});
	}

	handleOpen = (event) => {
		this.setState({
			opened: true
		});
	}

	render() {
		return (
			<Modal
				trigger={
					<Button
						onClick={this.handleOpen}
						labelPosition='left'
						content="Graphiques"
						icon="bar chart"
						primary
					/>
				}
				size='small'
				open={this.state.opened}
				onClose={this.handleClose}
			>
				<Header icon="bar chart" content={`Résultats: ${this.props.title}`}  />
				<Modal.Content>
					<ResponsiveContainer width="95%" minHeight={300}>
						<BarChart data={this.props.data ? this.props.data.map(d => ({
							...d,
							Oui: d.yesCount,
							"Peut-être": d.maybeCount,
							"Non": d.noCount

						})) : [] }>
							<XAxis dataKey="name" stroke="#555555" />
							<YAxis stroke="#555555" />
							<CartesianGrid stroke="#555555" strokeDasharray="3 3" />
							<Tooltip />
							<Legend />
							<Bar dataKey="Oui" stackId="1" fill="#27ae60" />
							<Bar dataKey="Peut-être" stackId="1" fill="#f39c12" />
							<Bar dataKey="Non" stackId="1" fill="#e74c3c" />
						</BarChart>
					</ResponsiveContainer>
				</Modal.Content>
				<Modal.Actions>
					<Button color='red' onClick={this.handleClose}>
						<Icon name="remove" /> Fermer
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}
