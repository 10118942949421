import React, { Component } from 'react';

import { Comment, Header, Button, Form } from 'semantic-ui-react';
import CommentRow from './comment.component';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchComments } from '../../actions/fetch.action';
import { addComment } from '../../actions/send.action';
import { dispatchComment } from '../../actions/dispatch.action';

class CommentForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			comment: ''
		};
		this.handleTextChange = this.handleTextChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.fetchComments(this.props.id, this.props.token);

		const { socket } = this.props.noodle;

		// Handle new comments
		socket.on('newComment', (comment) => this.props.dispatchComment(comment));
	}

	handleTextChange({ target: { value } }) {
		this.setState({
			comment: value,
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		// Retrive noodle specific socket
		const { socket } = this.props.noodle;

		// Add a comment
		this.props.addComment(this.state.comment, socket);

		// Flush textarea
		this.setState({
			comment: ''
		});
	}

	render() {

		return (
			<Comment.Group style={{ width: '95%' }}>
				<Header as="h3" dividing>Commentaires</Header>
				<Form reply onSubmit={this.handleSubmit}>
					<Form.TextArea value={this.state.comment} onChange={this.handleTextChange} />
					<Button type='submit' content='Ajouter un commentaire' labelPosition='left' icon='edit' primary />
				</Form>
				{
					this.props.comments ?
						this.props.comments.map(c => (
							<CommentRow
								author={c.user.fullName}
								text={c.content}
								date={c.createdAt}
								photo={c.user.photoPath}
							/>
						)) :
						<div>Loading ... </div>
				}
			</Comment.Group>
		);
	}
}

function mapStateToProps({ comments, noodle }) {
	return { comments, noodle };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchComments, addComment, dispatchComment }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
