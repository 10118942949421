import React from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

import {
  Create,
  Home,
  Login,
  NoodlesList,
  Settings,
  Noodle
} from './pages/';

import { PrivateRoute } from './misc/login';

import Footer from './components/display/footer.component';

import Navbar from './components/display/navbar.component';

import { Container } from './styles/container.style';

import config from './config.json';

export const BACK_URL = config.backUrl;
export const FRONT_URL = config.frontUrl;


const App = () => (
  <Router>
    <div id="react-root">
      <Navbar />
      <Container>
        <Route exact path="/" component={Home}/>
        <PrivateRoute path="/create" component={Create}/>
        <Route exact path="/login" component={Login}/>
        <Route path="/login/:token/:redirectPath" component={Login}/>
        <PrivateRoute path="/noodles" component={NoodlesList}/>
        <PrivateRoute path="/settings" component={Settings}/>
        <PrivateRoute exact path="/noodle/:id/edit" component={Create} />
        <PrivateRoute exact path="/noodle/:id" component={Noodle} />
      </Container>
      <Footer />
    </div>
  </Router>
)
export default App;
