import moment from 'moment';

moment.updateLocale('en', {
    relativeTime : {
        future: "dans %s",
        past:   "il y a %s",
        s:  "secondes",
        m:  "minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});

export default moment;