import React from 'react'
import { Icon, Step } from 'semantic-ui-react'

export default () => (
  <div>
    <Step.Group vertical>
      <Step>
        <Icon name='calendar' color='black' />
        <Step.Content 
          title='Proposez' 
          description='Suggérez des propositions' />
      </Step>

      <Step>
        <Icon name='share' color='blue' />
        <Step.Content 
          title='Partagez' 
          description="Partager le Noodle pour avoir l'avis d'autres personnes" />
      </Step>

      <Step>
        <Icon name='checkmark' color='green' />
        <Step.Content 
          title='Validez' 
          description="Choisissez la proposition la plus populaire" />
      </Step>
      </Step.Group>

  </div>
);

