import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import './index.css';

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxPromise from 'redux-promise';
import reducers from './reducers/index';

import 'semantic-ui-css/semantic.min.css';

// Styletron requirement
import Styletron from 'styletron-client';
import { StyletronProvider } from 'styletron-react';

const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);

// Styletron magic
const styleSheet = document.createElement('style');
document.head.appendChild(styleSheet);
const styletron = new Styletron([styleSheet], { prefix: 'st-' });


ReactDOM.render(
		<Provider store={createStoreWithMiddleware(reducers)}>
			<StyletronProvider styletron={styletron}>
				<App />
			</StyletronProvider>
		</Provider>
	,document.getElementById('root'));
// registerServiceWorker();
