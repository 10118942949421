import { FETCH_COMMENTS } from '../actions/fetch.action';
import { DISPATCH_COMMENT } from '../actions/dispatch.action';

export default function(state=[], action){
	switch(action.type){
		case FETCH_COMMENTS:
			return action.payload.data || state;
		case DISPATCH_COMMENT:
			return [ action.payload, ...state];
		default:
			return state;
	}
}