import React, { Component } from 'react';
import { Form, Checkbox } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { dispatchVote } from '../../../actions/dispatch.action';

class RadioGroup extends Component {
  constructor(props) {
    super(props);
    
    // Cas où la personne a déjà votée 
    this.state = {
        vote: this.props.answer > 0 ? this.props.answer : 0,
        number: this.props.number
    };
    this.handleChange = this.handleChange.bind(this);
    this.props.dispatchVote({...this.state});
  }
  handleChange(e, { vote }){
    
    this.setState({ vote, number: this.props.number });
    this.props.dispatchVote({ vote, number: this.props.number });
  }

  render() {
    
    return (
      <Form>
        <Form.Field>
          <Checkbox
            radio
            label='Oui'
            name='checkboxRadioGroup'
            vote={2}
            checked={this.state.vote === 2 }
            onChange={this.handleChange}
          />
        </Form.Field>
        { this.props.maybe ?
        <Form.Field>
          <Checkbox
            radio
            label='Peut-être'
            name='checkboxRadioGroup'
            vote={1}
            checked={this.state.vote === 1 }
            onChange={this.handleChange}
          />
        </Form.Field>
        : <div></div> }
        <Form.Field error>
          <Checkbox
            radio
            label='Non'
            name='checkboxRadioGroup'
            vote={0}
            checked={this.state.vote !== 2 && this.state.vote !==1 }
            onChange={this.handleChange}
          />
        </Form.Field>
      </Form>
    )
  }
}

function mapStateToProps({ vote }){
  return { vote };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({ dispatchVote }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RadioGroup);
