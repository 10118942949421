import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    Segment,
    Button,
    Checkbox,
    Divider,
} from 'semantic-ui-react';

import {
    Flex, Description
} from '../../../styles/general.style';

import { Link } from 'react-router-dom';

import Copy from '../../utils/copy.component';
import CSVExport from '../../utils/csvexport.component';


class NoodleHeader extends Component {
	constructor(props) {
    super(props);
 
    this.handleChange = this.props.handleChange;
    this.handleVoteDelete = this.props.handleVoteDelete;
  }
	render()
	{
	const { noodle, creator, anonymous, admin, link, active } = this.props ;

	return (noodle == null ? null:<Segment style={{width:"95%"}}>
		<Flex justify='space-between' height='inherit'>
			<div>
				<h2 style={{wordWrap: 'break-word'}}>{noodle.title}</h2>
				<i>Créé par {creator} </i>
			</div>
			<div>
				{/* On affiche le export seulement si la personne est admin dans le cas anonyme */}
				{(!(anonymous === true) || admin) &&
					<CSVExport noodle={noodle} />
				}
			</div>

		</Flex>
				<h4>Description</h4>
				<Divider />
				<Description>{noodle.description}</Description>
				<Divider />
			{admin ?
				<Flex wrap="wrap">
					<Copy link={link} />
					{/* On récup l'id du noodle*/}
					<Link to={link.match(/\/noodle\/.+/)+'/edit'}>
						<Button 
							color='blue' 
							content='Editer le Noodle'
							style={{margin: '4px'}}
						/>
					</Link>
					<Button 
							color='red' 
							content='Effacer toutes les réponses'
							style={{margin: '4px'}}
							onClick={this.handleVoteDelete}
						/>
					<Checkbox
						onChange={this.handleChange}
						toggle
						style={{alignSelf: 'center'}}
						checked={active}
						label="Noodle ouvert"
					/>
				</Flex>
			: <Copy link={link} />
		    }
	</Segment>)
}
}
function mapStateToProps({ vote, noodle }){
  return { vote, noodle };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({ }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NoodleHeader);
