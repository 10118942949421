import axios from 'axios';

import { BACK_URL } from '../App';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_NOODLE_LIST = 'FETCH_NOODLE_LIST';
export const FETCH_NOODLE = 'FETCH_NOODLE';
export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const FETCH_NOODLE_NUMBER = 'FETCH_NOODLE_NUMBER';

// Récupère des infos sur l'utilisateur


export function fetchUser(token) {
	const url = `${BACK_URL}/user/`;
	const request = axios({
		method: 'get',
		url,
		headers: {
			token
		}
	});

	return {
		type: FETCH_USER,
		payload: request
	};
};

export function fetchNoodleList(token) {
	const url = `${BACK_URL}/noodle/`;

	const request = axios({
		method: 'get',
		url,
		headers: {
			token
		}
	});

	return {
		type: FETCH_NOODLE_LIST,
		payload: request
	};
};

export function fetchNoodle(id, token) {
	const url = `${BACK_URL}/noodle/${id}`;
	const request = axios({
		method: 'get',
		url,
		headers: {
			token
		}
	}).then((req) => {
		req.data.entries = req.data.entries.sort((i, j) => {return i.number > j.number ? 1 : -1});
		return req;
	});

	return {
		type: FETCH_NOODLE,
		payload: request,
	}
};

export function fetchComments(id, token) {
	const url = `${BACK_URL}/noodle/${id}/comment`;
	const request = axios({
		method: 'get',
		url,
		headers: {
			token
		}
	});

	return {
		type: FETCH_COMMENTS,
		payload: request
	}
};

export function fetchNoodleNumber() {
	const url = `${BACK_URL}/count`;
	const request = axios.get(url);
	return {
		type: FETCH_NOODLE_NUMBER,
		payload: request
	}
}