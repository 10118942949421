export default (noodleList) => {
	const data = [["login"]];
	if(noodleList.entries){
		// Première ligne du csv
		noodleList.entries.forEach(title => data[0].push(title.name));

		// Votes des utilisateurs
		noodleList.userVotes.forEach(vote => {
			let temp = [vote.login];
			vote.entries.forEach(x => temp.push(x.answer));
			data.push(temp);
		})

		return data;
	}

	return [[]];
}