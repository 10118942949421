import React from 'react';

import { Table, Icon } from 'semantic-ui-react';

export default ({ user }) => (
	<Table.Row> 
		<Table.Cell collapsing>
			<a href={`https://linkcs.fr/user/${user.login}`} target='_blank' rel="noopener noreferrer">{user.fullName}</a>
		</Table.Cell>
		{user.entries.sort((i, j) => { return i.number > j.number ? 1 : -1 }).map(ent => (
				ent.answer === 2 ? 
				<Table.Cell positive textAlign='center'>
					<Icon name='checkmark' size='large' />
				</Table.Cell>
				: ent.answer === 1 ? 
				<Table.Cell warning textAlign='center'>
					<Icon name='help' size='large' />
				</Table.Cell>
				: ent.answer === 0 ?
				<Table.Cell negative textAlign='center'>
					<Icon name='close' size='large' />
				</Table.Cell>
				:
				<Table.Cell style={{backgroundColor: "#f9f9f9"}} textAlign='center'>
					<Icon name='minus' size='large' />
				</Table.Cell>
		))}
	</Table.Row>
);