import React from 'react'
import { Message } from 'semantic-ui-react'
import { Animate } from 'react-move';

const ErrorMessage = (props) => (
	<Animate
	default={{opacity:0}}
	data={{opacity:1}}
	duration={500}
	easing='easeQuadIn'
	>
	{ data => (
		  <Message
		  	negative
		  	style={data}
		  	icon="warning sign"
		  	header={props.title}
		    content={props.content}
		   />
	)}
	</Animate>
)

export default ErrorMessage;
