import React from 'react';

import { Flex } from '../styles/general.style';

import NoodleList from '../components/noodle/list/noodlelist.container';

import { Divider } from 'semantic-ui-react';

const NoodleListPage = () => (
	<Flex direction='column'>
		<h1>Liste des Noodles</h1>
		<h2> Noodles que vous avez lancés</h2>
		<NoodleList creator />
		<Divider />
		<h2> Noodles auxquels vous avez participé</h2>
		<NoodleList />
	</Flex>
);

export default NoodleListPage;