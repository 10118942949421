import { combineReducers } from 'redux';

import UserReducer from './user.reducer';
import NoodleListReducer from './noodlelist.reducer';
import VoteReducer from './vote.reducer';
import NoodleReducer from './noodle.reducer';
import CommentsReducer from './comments.reducer';
import NoodleNumberReducer from './noodlenumber.reducer';

const rootReducer = combineReducers({
	user: UserReducer,
	noodleList: NoodleListReducer,
	vote: VoteReducer,
	noodle: NoodleReducer,
	comments: CommentsReducer,
	noodleNumber: NoodleNumberReducer
});

export default rootReducer;