import React, { Component } from 'react';

import { Flex, P } from '../styles/general.style';

import Steps from '../components/display/noodlesteps.component';
import Loader from '../components/display/loader.component';

import { Animate } from 'react-move';

import { Statistic } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchNoodleNumber } from '../actions/fetch.action';

class HomePage extends Component {
	componentDidMount() {
		this.props.fetchNoodleNumber();
	}

	render() {
		console.log(this.props.noodleNumber.count);
		return (
			<Flex direction='column' align='center'>
				<h1> Bienvenue sur Noodle ! </h1>
				<img src={"/noodle_colorized.png"} alt="Logo de Noodle" id="logo" />
				<div>
					<P>Noodle, c'est Notre Doodle. Plus précisement, c'est un Doodle à la CentraleSupélec, qui utilise l'Oauth ViaRézo et empêche le pourrissage.</P>
					<P>Avec Noodle, vous avez l'assurance que vos sondages seront utilisables.</P>
					<P>Vous pouvez créer et utiliser des sondages pour vous, vos amis, vos étages, vos assos.
					Le site est une version adaptée aux besoins de l'associatif.</P>
				</div>
					{this.props.noodleNumber.count || this.props.noodleNumber.count === 0 ?
						<Animate
							start={{
								number: 0
							}}
							enter={[{
                                number: [
                                    this.props.noodleNumber.count / 1000
                                ],
                                timing: {
                                    duration: 2000,
                                }
                            }]}
						>
						{ ({ number })  => (
							<Statistic
								value={parseInt(number*1000, 10)}
								label="Noodles créées"
							/>
						)}
						</Animate> :
						<Loader />
					}
				<Steps />
			</Flex>
		);
	}
}

function mapStateToProps({ noodleNumber }){
	return { noodleNumber };
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ fetchNoodleNumber }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
