import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({component: Component, ...rest}) => {
	// S'il n'y a pas de token ou s'il n'est pas conforme on redirige
	const notLogged = !(localStorage.getItem('noodleToken') 
	&& localStorage.getItem('noodleToken').match(/ey/));

		// Redirection suivant le composant
		// On enleve les slashs pour éviter les pbs			

		// Pour connect de react-redux on doit passer un composant
		// DU coup on crée un composant ici
	return (
		<Route { ...rest } 
			render={props => (
			!notLogged ? <Component {...props} />
			: <Redirect to ={{
					pathname: '/login',
					state: { from: props.location} 
				}} />
			)}
		/>
	);
}
