import React, { Component } from 'react';

import { BACK_URL } from '../App';

export default class LoginPage extends Component {
	componentDidMount() {
	 	// Si on a reçu un token on le stocke , sinon on redirige vers le back pour l'avoir
		localStorage.clear('noodleToken');
	 	if (this.props.match.params.token){
			localStorage.setItem('noodleToken', this.props.match.params.token);

			const path = 
			// Si le back nous a redirigé
							this.props.match.params.redirectPath ?
							this.props.match.params.redirectPath.replace(/&\$&/g, '/') :
							'/';

			//const path = this.props.match.params.redirectPath.replace(/&\$&/g, '/');
			// Le back renvoie "undefined"
			this.props.history.push(path && path !== "undefined" ? path : '/');
		}
		else{
			const alteredPath = this.props.location.state ? 
							this.props.location.state.from.pathname.replace(/\//g, '&$$&') : 
							this.props.match.url.replace(/\//g, '&$$&');
			// Redirection
			window.location.assign(`${BACK_URL}/auth/login/${alteredPath}`);
		
		}
	}
	render(){
		return <div>Vous allez être redirigé</div>;
	}
}
