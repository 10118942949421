import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchNoodleList } from '../../../actions/fetch.action';

import { Table } from 'semantic-ui-react';
import { WrapTableBody } from '../../../styles/table.style';
import Loader from '../../display/loader.component';
import ListRow from './listrow.component';

class NoodleList extends Component {

	componentDidMount() {
		const token = localStorage.getItem('noodleToken');
		this.props.fetchNoodleList(token);
	}


	render() {
		const type = this.props.creator ? 'created' : 'voted';
		return (
			<Table basic='very'>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell> Ouvert </Table.HeaderCell>
						<Table.HeaderCell> Titre </Table.HeaderCell>
						<Table.HeaderCell> Nombre de participants </Table.HeaderCell>
						<Table.HeaderCell> Groupe </Table.HeaderCell>
						<Table.HeaderCell> Dernière activité </Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<WrapTableBody>
					{
						this.props.noodleList[type] ?
						this.props.noodleList[type]
						// On trie en mettant les opens en haut puis par date
						.sort((a, b) => ( 
											(a.open && b.open) || (!a.open && !b.open) ? 
												new Date(b.lastActivity) - new Date(a.lastActivity) :
											(a.open && !b.open) ? 
												-1 : 
												1
										)
							)
						.map((el, index) => (
						<ListRow 
							active={el.open} 
							title={el.title}
							participants={el.voteNumber}
							group={el.group || 'Aucun'}
							lastActivity={el.lastActivity}
							id={el.noodleId}
							key={el.noodleId}
							duration={500+50*index} // Delay suivant la position dans la liste
						/>
						))
						: <Table.Row><Table.Cell><Loader /></Table.Cell></Table.Row>
					} 
				</WrapTableBody>
			</Table>
		);
	}
}


function mapStateToProps({ noodleList }){
	return { noodleList };
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ fetchNoodleList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NoodleList);
