import React from 'react';
import { Flex } from '../../styles/general.style';
import { Button } from 'semantic-ui-react';
import {
    CSVLink
} from 'react-csv';
import formatData from '../../misc/formatdata';


export default ({ noodle }) => (
	<Flex direction="column" justify="space-around" align='right'>
		<CSVLink
			data={formatData(noodle)}
			separator=";"
			filename={noodle.title+".csv"}
		>
			<Button icon='share' content='Exporter en .csv' />
		</CSVLink>
	</Flex>
);