export const DISPATCH_VOTE = 'DISPATCH_VOTE';
export const RESET_NOODLE = 'RESET_NOODLE';
export const DISPATCH_COMMENT = 'DISPATCH_COMMENT';

export function dispatchVote(data){
	return {
		type: DISPATCH_VOTE,
		payload: data
	}
}

export function resetNoodleState(){
	return {
		type: RESET_NOODLE,
		payload: 'reset'
	}
}

export function dispatchComment(comment) {
	return {
		type: DISPATCH_COMMENT,
		payload: comment
	}
}