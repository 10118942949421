import { styled } from 'styletron-react';

export const Container = styled('div', () => ({
	marginLeft: '20%',
	marginRight: '20%',
	height: '100%',
	padding: '30px',
	borderRadius: '5px',
	backgroundColor: 'white',
	'box-shadow': "0px 2px 2px rgba(50, 50, 50, 0.5)",
	'@media (max-width: 800px)': {
		marginLeft: '2%',
		marginRight: '2%',
		marginBottom: '10px',
		padding: "15px"
	},
	'@media (min-width: 801px) and (max-width: 1200px)': {
		marginLeft: '10%',
		marginRight: '10%',
		marginBottom: '10px',
		padding: "25px"
	},
	animation: "fadeIn 1s"
}));

/*
padding 30px
background color: whte
box-shadow -4px 6px 9px rgba(50, 50, 50, 0.5)
margin: 15px auto 30px
*/