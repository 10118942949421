import React from 'react';

import { Table, Icon } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { Animate } from 'react-move';

import Moment from '../../../misc/formatdate';

export default (props) => (
	<Animate
		default={{opacity:0}} 
		data={{opacity:1}}
		duration={props.duration}
		easing='easeQuadIn'
	>
		{ data => (
		<Table.Row style={data}>
	    	<Table.Cell>
	    		<Icon name='circle' color={props.active ? 'green' : 'red'} />
	    	</Table.Cell>
	    	<Table.Cell>
	    		<Link to={`/noodle/${props.id}`}>
	    			{props.title}
	    		</Link>
	    	</Table.Cell>
	    	<Table.Cell>{props.participants}</Table.Cell>
	    	<Table.Cell>{props.group}</Table.Cell>
	    	<Table.Cell>{Moment(props.lastActivity).fromNow()}</Table.Cell>
	    </Table.Row>
	    )}
	</Animate>
);