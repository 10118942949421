import { DISPATCH_VOTE } from '../actions/dispatch.action';

export default function(state={}, action){
	
	
	switch(action.type){
		case DISPATCH_VOTE:
			return {...state, [action.payload.number]:action.payload.vote || 0};
		default:
			return state;
	}
}