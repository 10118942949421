import React from 'react';

import { Comment } from 'semantic-ui-react';
import Moment from '../../misc/formatdate';

import { Animate } from 'react-move';

export default (props) => (
	<Animate
		start={{opacity:0}}
		enter={{opacity: [1], duration: 1500}}
	>
	{({opacity}) => (
		<Comment style={{ opacity }}>
	      <Comment.Avatar src={props.photo} />
	      <Comment.Content>
	        <Comment.Author as='a'>{props.author}</Comment.Author>
	        <Comment.Metadata>
	          <div>{Moment(props.date).fromNow()}</div>
	        </Comment.Metadata>
	        <Comment.Text>{props.text}</Comment.Text>
	      </Comment.Content>
	    </Comment>
	)}
    </Animate>
);