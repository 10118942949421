import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchUser } from '../../actions/fetch.action';
import { Flex } from '../../styles/general.style';

import { Checkbox } from 'semantic-ui-react';

class Notifications extends Component {

	componentDidMount() {
		const token = localStorage.getItem('noodleToken');
		this.props.fetchUser(token);
	}

	render() {
		
		return (
			<Flex direction='column'>
				<h3>Notifications automatiques par mail </h3>
				{ this.props.user.groups ? 
					this.props.user.groups.map((el) => (
						<div>
							<span>{el.text}</span>
							<Checkbox toggle />
						</div>
					))
					: 'Loading'
				}
				</Flex>  
		);
	}
}

function mapStateToProps({ user }){
	return { user };
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ fetchUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);