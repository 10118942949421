import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchUser, fetchNoodle } from '../../../actions/fetch.action';
import { postNoodle } from '../../../actions/send.action';

import { Flex } from '../../../styles/general.style';
import { Form, Button, Popup, Icon } from 'semantic-ui-react';
import Loader from '../../display/loader.component';
import ErrorMessage from '../../messages/errormessage.component';

import { withRouter } from 'react-router-dom';

class NoodleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      group: '',
      id: this.props.match.params.id || 0, // id du noodle si ça concerne un edit
      edit: this.props.match.path === '/noodle/:id/edit', // Si c'est un edit
      anonymous: false,
      comments: true,
      error: false,
      loading: false,
      propositions: [
        {
          id: '',
          name: '',
          maybe: false, // Si on peut voter peut être
          number: 1
        },
      ]
    }
  }

  async componentDidMount() {
    try {
      const token = localStorage.getItem('noodleToken');
      // Si c'est un édit on fetch les données
      if (this.props.match.path === '/noodle/:id/edit') {
        await this.props.fetchNoodle(this.props.match.params.id, token);
        const nd = this.props.noodle;

        const propositions = nd.entries.map(
          ({ id, name, maybe, number }) => ({
            id,
            name,
            maybe,
            number,
          })
        );


        propositions.push({
          id: '',
          name: '',
          number: nd.entries.length + 1,
          maybe: false
        });

        this.setState({
          ...this.state,
          title: nd.title,
          description: nd.description,
          group: nd.groupId,
          id: nd.noodleId,
          anonymous: nd.anonymous,
          comments: nd.commentsEnabled,
          propositions
        });
      }
      this.props.fetchUser(token);
    } catch (err) {
      // TODO: error handling
      console.error(err);
    }

  }

  handleInputChange = ({ target }) => {
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value
    });
  }

  handlePropositionChange = ({ target }) => {
    const propositions = [...this.state.propositions];
    // On récupère la bonne proposition et sa valeur
    propositions.map((proposition, index) => {
      if (`${proposition.number}` === target.name) {
        // Si la proposition était vide et que c'est la dernière de la liste
        // On rajoute un champ
        if (proposition.name === "" && index === propositions.length - 1) {
          propositions.push({
            id: '',
            name: '',
            maybe: false,
            number: propositions.length + 1
          })
        }
        proposition.name = target.value;
      }
      return null;
    })
    this.setState({
      propositions
    });
  }

  handlePropositionOrderChange = (number, direction) => {
    //direction : +1 pour descendre, -1 pour remonter
    const propositions = [...this.state.propositions];
    if (number + direction > 0 && number +direction < propositions.length){
      const propositions = [...this.state.propositions];
      const buffer = propositions[number - 1 + direction];
      propositions[number -1 + direction] = propositions[number - 1];
      propositions[number -1] = buffer;
      propositions[number -1].number = number;
      propositions[number -1 + direction].number = number + direction;
      this.setState({
        propositions
      });
    }

  }

  handleToggle = ({ target }, data) => {
    if (data.name === 'anonymous') {
      this.setState({
        anonymous: !this.state.anonymous
      });
    }
    
    else if (data.name === 'comments') {
      this.setState({
        comments: !this.state.comments
      });
    }
    
    else {
      const propositions = [...this.state.propositions];
      // On récupère la bonne proposition et sa valeur
      propositions.map((p) => {
        if (`${p.number}` === data.name) {
          p.maybe = !p.maybe;
        }
        return null;
      })
      this.setState({
        propositions
      });
    }
  }

  handleDropdown = (event) => {
    this.setState({
      group: event.target.children[0].innerText
    })
  }

  handleSubmit = async (event) => {
    try {
      event.preventDefault();
      this.setState({ loading: true });
      const token = localStorage.getItem('noodleToken');

      const res = await this.props.postNoodle(this.state, this.state.id, token);

      // Si le noodle est mal rempli
      if (!res.payload.request || res.payload.request.status === 400) {
        this.setState({ error: true, loading: false })
      }
      // Si le noodle est bien rempli
      else if (res.payload.data) {
        // On redirige après le fetch
        await this.props.fetchNoodle(res.payload.data.noodleId, token);
        this.props.history.push(`/noodle/${res.payload.data.noodleId}`);
      }

      this.setState({
        title: '',
        description: '',
        group: '',
        id: this.props.match.params.id || 0, // id du noodle si ça concerne un edit
        edit: this.props.match.path === '/noodle/:id/edit',
        anonymous: false,
        comments: true,
        propositions: [
          {
            id: '',
            name: '',
            maybe: false, // Si on peut voter peut être
            number: 1
          }
        ]
      });
    } catch (err) {
      // TODO: error handling
      console.error(err);
    }

  }

  render() {

    return (
      // On attend d'avoir les données de l'utilisateur
      this.props.user.groups ?
        // Et si c'est un edit il faut qu'il soit admin
        !this.state.edit || this.props.noodle.admin ?
          <Form onSubmit={this.handleSubmit} loading={this.state.loading}>
            <Flex direction='column'>
              <Form.Input
                label="Titre"
                required
                placeholder="Titre"
                icon='pencil'
                name="title"
                error={this.state.title.length > 255}
                value={this.state.title}
                onChange={this.handleInputChange}
              />
              <Form.TextArea
                label="Description"
                required
                error={this.state.description.length > 65535}
                name="description"
                value={this.state.description}
                placeholder="Description de votre Noodle"
                onChange={this.handleInputChange}
              />
              <Flex>
                {this.props.user.groups.length !== 0 &&
                  <Form.Dropdown
                    label='Groupe (Facultatif)'
                    placeholder='Selectionnez un Groupe (facultatif)'
                    onChange={this.handleDropdown}
                    selection
                    value={this.state.group}
                    options={this.props.user.groups}
                  />
                }
                <Popup
									trigger={<Form.Checkbox toggle onChange={this.handleToggle} name='comments' checked={this.state.comments} label="Commentaires" />}
									content="Activez cette option pour autoriser les commentaires"
									position='left center'
                />
                <Popup
			            trigger={<Form.Checkbox toggle onChange={this.handleToggle} name='anonymous' checked={this.state.anonymous} label="Anonyme" />}
                  content="Activez cette option pour que le noodle soit anonyme"
                  position='right center'
                />
              </Flex>
              <h4> Propositions du Noodle</h4>
              {this.state.propositions.map((el) => (
                <Flex key={el.number} direction="row" style={{"justify-content":"space-between", "align-items":"center"}}>
                  <Form.Input
                    required={el.number === 1}
                    error={el.name.length > 255}
                    label={`Proposition ${el.number}`}
                    placeholder="Proposition"
                    name={el.number}
                    value={el.name}
                    onChange={this.handlePropositionChange}
                  />
                  <Popup
										trigger={<Form.Checkbox toggle onChange={this.handleToggle} checked={el.maybe} name={`${el.number}`} label='Autoriser "Peut-être"' />}
										content="Activez pour autoriser la réponse Peut-être."
										position='top center'
									/>
									<div>
										<Popup
											trigger={<Button type="button" onClick={(e) => this.handlePropositionOrderChange(el.number, -1)}><Icon style={{"margin":"auto"}} className="fitted" name="arrow up" align="center" color="grey"/></Button>}
											content="Remonter cette proposition"
											position='top center'
										/>
										<Popup
											trigger={<Button type="button" onClick={(e) => this.handlePropositionOrderChange(el.number, 1)}><Icon style={{"margin":"auto"}} className="fitted" name="arrow down" align="center" color="grey"/></Button>}
											content="Descendre cette proposition"
											position='top center'
										/>

									</div>
								</Flex>
							))}
							<div>
								{
									this.state.error &&
									<ErrorMessage
										title="Il y a eu une erreur"
										content="Veuillez remplir correctement les champs"
									/>
								}
								<Button
									content='Enregistrer'
									color='green'
									labelPosition='left'
									icon='save'
									loading={this.state.loading}
									type='submit'
								/>
							</div>
						</Flex>
						{/* TODO : Message positif en cas de soumission réussie*/}
					</Form>
					: <ErrorMessage
						title="Vous n'êtes pas autorisés à accéder à cette page"
						content="Vous n'êtes pas l'administrateur de ce Noodle"
					/>

				: <Loader />
    );
  }
}

function mapStateToProps({ user, noodle }) {
  return { user, noodle };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchUser, postNoodle, fetchNoodle }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NoodleForm));
