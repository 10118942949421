import React from 'react';

import { Navbar, NavItem, NavItemText } from '../../styles/navbar.style';
import { BACK_URL } from '../../App';
import { Button, Icon } from 'semantic-ui-react';

export default () => {
	const token = localStorage.getItem('noodleToken');
	// Forme d'un JWT
	const logged = token && token.match(/^ey/);

	return (
		<div>
			<Navbar style={{marginBottom: 0}}>
				<NavItem to='/' brand>
					<Icon name='sticky note outline' />
					<h2>Noodle</h2>
				</NavItem>

				{/* On affiche ces onglets que si la personne est loggée */}
				{logged && (
					<NavItem to='/create'>
						<Icon name="pencil" />
						<NavItemText>Créer un Noodle</NavItemText>
					</NavItem>)
				}
				{
					logged && (
						<NavItem to='/noodles'>
							<Icon name='browser' />
							<NavItemText>Mes Noodles</NavItemText>
						</NavItem>)
				}
				{/*<NavItem to='/settings'>
						<Icon name='wrench' />
						<NavItemText>Paramètres</NavItemText>
					</NavItem>*/}

				<NavItem to='/'>
					<Button
						color={logged ? 'red' : 'green'}
						size='large'
						icon='power'
						content={logged ? 'Logout' : 'Login'}
						onClick={() => {
							// Si on est déjà loggés on se logout
							if (logged) {
								localStorage.clear('noodleToken');
							}
							else {
								// On se log
								console.log(`${BACK_URL}/auth/login`)
								window.location.assign(`${BACK_URL}/auth/login`);
							}
						}}
					/>
				</NavItem>
			</Navbar>
		</div>
	);
}
