import { styled } from 'styletron-react';

export const Flex = styled('div', (props) => ({
	display: 'flex',
	alignItems: props.align ? props.align : 'inherit',
	flexDirection: props.direction ? props.direction : 'row' ,
	justifyContent: props.justify ? props.justify : 'space-around',
	width : props.width ? `${props.width}%` : '100%', 
	height : props.height ? `${props.height}%` : null,
	'flex-wrap' : props.wrap || 'initial',
	'@media screen and (max-width: 450px)': {
		flexDirection: 'column'
	},
	animation: "fadeIn 0.5s ease-in-out"	
}));

export const FlexItem = styled('div', (props) => ({
	flexBasis: '100%'
}));

export const Scrollable = styled('div', () =>({
	width:"100%",
	overflow: 'auto',
	padding: "10px",
	flexDirection: 'column'
}));

export const P = styled('p', () => ({
	textAlign: 'justify',
	animation: "fadeIn 2s"
}))

export const Description = styled('div', () => ({
	wordWrap: 'break-word',
	animation: "fadeIn 2s",
	whiteSpace: 'pre-wrap'
}))