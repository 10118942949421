import React from 'react';

import NoodleForm from '../components/noodle/editing/noodleform.container';

import { Flex } from '../styles/general.style';

const CreatePage = () => (
	<Flex direction='column'>
		<h1>
			Création d'un Noodle
		</h1>
		<NoodleForm />
	</Flex>
);

export default CreatePage;