import { styled } from 'styletron-react';

import { Link } from 'react-router-dom';


export const Navbar = styled('div', () => ({
	backgroundColor: '#34495e',
	paddingLeft: "3%",
	paddingRight: "3%",
	width: '100%',
	height: '100px',
	display: '-webkit-box',
	flexDirection: 'row',
	alignItems: 'center',
	'-webkit-box-shadow': '5px 5px 5px 0px rgba(0,0,0,0.2)',
	'-moz-box-shadow': '5px 5px 5px 0px rgba(0,0,0,0.2)',
	'box-shadow': '5px 5px 5px 0px rgba(0,0,0,0.2)',
	justifyContent: 'space-around',
	marginBottom: '20px'
}));

export const NavItem = styled(Link, (props) => ({
	display: 'flex',
	flexDirection: props.brand ? 'row' : 'column',
	alignItems: 'center',
	justifyContent: 'space-around',
	fontSize: '24px',
	color: 'white',
	height: '70%'
}));

export const NavItemTel = styled(NavItem, () => ({
	'@media screen and (min-width: 800px)': {
		display: 'none'
	},
}));


export const NavItemDesk = styled(NavItem, () => ({
	'@media screen and (max-width: 799px)': {
		display: 'none'
	}
}))

export const NavItemText = styled('div', () => ({
	'@media (max-width: 800px)': {
		display: 'none'
  	},
  	fontSize: '22px'
}));