import React from 'react';

import { Animate } from 'react-move';

import { Message } from 'semantic-ui-react';

export default ({ header, content }) => (
	<Animate 
	default={{opacity:0}} 
	data={{opacity:1}}
	duration={500}
	easing='easeQuadIn'
	>
	{ data => (
		<Message
			success
			style={data}
			icon="checkmark"
			header={header}
			content={content}
		/> 
	)}
	</Animate>
);