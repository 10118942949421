import React from 'react';

import { Footer } from '../../styles/footer.style';
import { Icon, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ViaRezo from './viarezo.component';

export default () => (
	<Footer>
		<Divider />
		<Link to="/">Accueil</Link> - © Noodle
		<Divider />
		<Icon name='code' />Édité par <ViaRezo />
		<Divider />
	</Footer>
);
