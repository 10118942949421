import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Popup, Button } from 'semantic-ui-react';

export default class Copy extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	handleOpen = () => {
		this.setState({ open: true });
		setInterval(this.handleClose, 1500);
	}

	handleClose = () => {
		this.setState({ open: false });
	}

	render() {
		return (
			<Popup
					trigger={
						<CopyToClipboard text={this.props.link} onCopy={this.handleOpen}>
							<Button icon='copy' content='Copier le lien' />
						</CopyToClipboard>
					}
					open={this.state.open}
					content="Lien copié dans le presse-papier !"
				/>
		);
	}
}
