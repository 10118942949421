import React, { Component } from 'react';

import { FRONT_URL } from '../App';

import { Flex } from '../styles/general.style';

import { Button, Message } from 'semantic-ui-react';

import Loader from '../components/display/loader.component';
import NoodleTable from '../components/noodle/display/noodletable.container';
import NoodleHeader from '../components/noodle/display/noodleheader.component';
import PositiveMessage from '../components/messages/positivemessage.component';
import CommentForm from '../components/comments/commentform.container';
import Chart from '../components/noodle/display/resultschart.component';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addVote, changeStatus, deleteVotes } from '../actions/send.action';
import { fetchNoodle, fetchUser } from '../actions/fetch.action';
import { resetNoodleState } from '../actions/dispatch.action.js';

class Noodle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: this.props.noodle.open,
			voteSent: false,
			token: localStorage.getItem('noodleToken'),
			loading: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleVoteDelete = this.handleVoteDelete.bind(this);
		this.handleRegister = this.handleRegister.bind(this);
	}

	componentDidMount() {
		this.props.fetchNoodle(this.props.match.params.id, this.state.token);
		this.props.fetchUser(this.state.token);
	}

	async handleChange() {
		try {
			await this.props.changeStatus(!this.state.active, this.props.match.params.id, this.state.token);
			this.props.fetchNoodle(this.props.match.params.id, this.state.token);
			this.setState({
				active: !this.state.active
			});
		} catch (err) {
			// TODO: error handling
			console.error(err);
		}
	}

	async handleVoteDelete() {
		try {
			await this.props.deleteVotes(this.props.match.params.id, this.state.token);
			this.props.fetchNoodle(this.props.match.params.id, this.state.token);
			this.props.vote = { 1:0,2:0,3:0};
		} catch (err) {
			// TODO: error handling
			console.error(err);
		}
	}

	componentWillUnmount() {
		// On reset le noodle contenu dans le state
		this.props.resetNoodleState();
	}

	componentWillReceiveProps(nextProps) {
		// On update l'état du noodle dès qu'on a l'info
		// Le composant réexecute componentwillreceive props si le noodle passe de disabled à enabled
		// Ce qui l'empeche de s'activer d'où la condition
		this.setState({ active: nextProps.noodle.open });
	}

	async handleRegister() {
		try {
			// On envoie les données
			this.setState({ loading: true })
			const res = await this.props.addVote(this.props.vote, this.props.match.params.id, this.state.token);

			// Refetch data
			this.props.fetchNoodle(this.props.match.params.id, this.state.token);

			// Display message on success
			if (res.payload.data && res.payload.request.status !== 400) {
				this.setState({ voteSent: true, loading: false });
				setTimeout(() => this.setState({ voteSent: false }), 3000);
			}

		} catch (err) {
			// TODO: error handling
			console.error(err);
		}
	}

	render() {
		return (
			// On attends d'avoir les données avant de render
			this.props.noodle ?

				<Flex
					direction='column'
					justify='space-between'
					align='center'
				>
					<NoodleHeader
						noodle={this.props.noodle}
						handleChange={this.handleChange}
						handleVoteDelete={() => this.handleVoteDelete()}
						active={this.state.active}
						creatorPhoto={this.props.noodle.creatorPhotoPath}
						admin={this.props.noodle.admin}
						link={`${FRONT_URL}/noodle/${this.props.noodle.noodleId}`}
						creator={this.props.noodle.creatorFullName}
						anonymous={this.props.noodle.anonymous}
						token={this.state.token}
					/>
					{/* */}
					{
						this.props.noodle.entries || this.props.noodle.userVotes === [] ?
							<NoodleTable
								noodle={this.props.noodle}
								anonymous={this.props.noodle.anonymous}
								opened={this.state.active}
							/> :
							<Loader />
					}
					<Flex direction='column'>
						{this.state.voteSent &&
							<PositiveMessage
								header="Vote pris en compte"
								content="Votre vote a bien été pris en compte."
							/>
						}
						{/* On affiche le bouton seulement si le noodle est actif*/}
						{this.state.active === false ?
							<Message
								icon='lock'
								header='Vote fermé'
								content="Désolé, les votes ont été fermés par l'adminstrateur"
							/>
							: ((this.state.active === true) && <Button
								style={{ margin: "5px" }}
								color='green'
								onClick={this.handleRegister}
								icon='save'
								loading={this.state.loading}
								labelPosition='left'
								content={this.props.noodle.voted === true ? 'Modifier' : 'Enregistrer'}
							/>)
						}
						{/* Si le noodle est anonyme et que l'utilisateur n'est pas admin on affiche rien*/}
						{!this.props.noodle.anonymous &&
							<Chart
								title={this.props.noodle.title}
								data={this.props.noodle.entries}
							/>
						}
					</Flex>
					{ /*Si les commentaires sont autorisés*/}
					{this.props.noodle.commentsEnabled &&
						<CommentForm
							id={this.props.match.params.id}
							token={this.state.token}
						/>
					}
				</Flex> :
				<Loader />
		);
	}
}


function mapStateToProps({ vote, noodle }) {
	return { vote, noodle };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ addVote, resetNoodleState, fetchNoodle, fetchUser, changeStatus, deleteVotes }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(Noodle);
