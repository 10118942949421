import axios from 'axios';

import { BACK_URL } from '../App';

export const ADD_NOODLE = 'ADD_NOODLE';
export const ADD_VOTE = 'ADD_VOTE';
export const ADD_COMMENT = 'ADD_COMMENT';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const DELETE_VOTES = 'DELETE_VOTES';

export function postNoodle(data, id, token){
	const url = `${BACK_URL}/noodle` + (data.edit ? `/${id}` : '');
	const request = axios({
		// Si c'est un édit on fait un put
		method: data.edit ? 'put' : 'post',
		url,
		headers: {
			token,
			"Content-Type": "application/json"
		},
		data: {
			title: data.title,
			description: data.description,
			anonymous: data.anonymous,
			commentsEnabled: data.comments,
			entries: data.propositions
		}
	});
	
	return {
		type: ADD_NOODLE,
		payload: request
	}
}

export function addVote(data, id, token){
	const url = `${BACK_URL}/noodle/${id}`;
	const votes = [];
	for(let propo in data){
		votes.push({
			number: propo,
			answer: data[propo]
		});
	}
	const request = axios({
		method: 'post',
		url,
		headers: {
			token,
			"Content-Type": "application/json"
		},
		data: {
			votes
		}
	});
	
	return {
		type: ADD_VOTE,
		payload: request
	}
}

export function addComment(comment, socket){
	socket.emit('newComment', { content: comment });
	return {
		type: ADD_COMMENT,
		payload: null
	}
}

export function changeStatus(status, id, token){
	const url = `${BACK_URL}/noodle/${id}/status`;
	const request = axios({
		method: 'put',
		url,
		headers: {
			token,
			"Content-Type": "application/json"
		},
		data: {
			//open: status
		}
	});
	return {
		type: CHANGE_STATUS,
		payload: request
	}
}

export function deleteVotes(id,token) {
	const url = `${BACK_URL}/noodle/${id}/votes`;
	const request = axios({
		method: 'delete',
		url,
		headers: {
			token,
			"Content-Type": "application/json"
		},
		data: {

		}
	});
	return {
		type: DELETE_VOTES,
		payload: request
	}
}
